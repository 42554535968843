.App {
  text-align: center;
  background-color: #261129;
  min-height: 1000vh;
  color: white !important;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  
}

.App-logo_header {
  height: 10vmin;
  margin-top: 20%;
  pointer-events: none;
  text-align: left;
}

.row{
  width: 80% !important;
}

.row-full{
  width: 100% !important;
}

.btn-degrad-header{
  background: linear-gradient(270deg, #E60E81 0%, #FF7500 100%) !important;
  width: 200px;
  height: 30px;
  font-size: 1.3em !important;
  padding-top: 0px !important;
}

.formUpload{
  margin-top: 10%;
}

.App-header {
  background-color: #261129;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white !important;
  padding-top: 5vh;
}

.App-link {
  color: #FFFFFF;
}

.btn-degrad{
  background: linear-gradient(270deg, #E60E81 0%, #FF7500 100%) !important;
  width: 350px;
  border-radius: 8px;
  border-width: 0;
  color: white !important;
  margin-bottom: 10px;
}
.mainContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.resultContainer, .historyItem { 
  flex-direction: row;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
}

.historyContainer {
  flex-direction: column;
  display: flex;
  height: 200px;
  align-items: center;
  flex-grow: 5;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inputContainer > .errorLabel {
  color: red;
  font-size: 12px;
}

.inputBox {
  height: 48px;
  width: 400px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  padding-left: 8px;
}